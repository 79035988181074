// Adyen/5.59.0

<template>
  <div :class="['payment-method-main', {'pt-3': onlyCvc}]">
    <div
      v-if="!onlyCvc"
      id="add-card"
      ref="addCard"
      class="adyen-provider-main"
    />

    <div
      v-if="onlyCvc"
      id="cvc-card"
      ref="cvcCard"
    />
  </div>
</template>

<script>
import AdyenCheckout from '@adyen-web-5.59.0';
import '@adyen-web-5.59.0/dist/adyen.css';

import { mapActions, mapState } from 'vuex';
import { adyenEnvironment } from './utils';
import state from './stores/adyenStore';

import {
  DEFAULT_LOCALE, DEFAULT_PLACEHOLDERS, MASTERCARD_TYPE, PAYMENT_METHODS_CONFIG,
} from '../constants/adyen.const';

const environment = adyenEnvironment();

const localStore = 'AdyenStoreLocal/5.59.0';

export default {
  name: 'ProviderAddPaymentMethod',

  props: {
    userUuid: {
      type: String,
      required: true,
    },

    locale: {
      type: String,
      default: DEFAULT_LOCALE,
    },

    onlyCvc: {
      type: Boolean,
      default: false,
    },

    cvcConfig: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      currentCvcUuid: null,
      card: null,
    };
  },

  computed: {
    ...mapState(localStore, ['originKey']),
  },

  async created() {
    const store = this.$store;

    if (!store.state[localStore]) {
      store.registerModule(localStore, state);
    }

    await this.loadOriginKey(this.userUuid);

    let mountPoint = this.$refs.addCard;

    if (!this.onlyCvc) {
      this.card = this.createCardComponent(mountPoint);
      this.$emit('on:ready');
    } else {
      mountPoint = this.$refs.cvcCard;
      this.card = this.createCVCComponent(mountPoint);
      this.$emit('on:cvc-ready');

      this.currentCvcUuid = this.cvcConfig.userUuid;
    }
  },

  methods: {
    ...mapActions(localStore, ['loadOriginKey']),

    buildCheckoutConfiguration() {
      return {
        environment,
        locale: this.locale || DEFAULT_LOCALE,
        onChange: onChangeState => {
          const candidatePaymentMethod = {
            isValid: onChangeState.isValid,
            paymentMethod: onChangeState.isValid ? onChangeState.data.paymentMethod : null,
          };

          this.$emit('update:provider-candidate-payment-method', candidatePaymentMethod);
        },

        onError: errorMsg => {
          this.$emit('on:error', errorMsg.errorI18n);
        },
        clientKey: this.originKey,
        paymentMethodsResponse: PAYMENT_METHODS_CONFIG,
        onPaymentCompleted: (result, component) => {
          console.info(result, component);
        },
        paymentMethodsConfiguration: this.buildCardConfiguration(),
      };
    },

    buildCardConfiguration() {
      return {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          placeholders: DEFAULT_PLACEHOLDERS,
        },
      };
    },

    async createCardComponent(mountPoint) {
      const checkoutConfiguration = this.buildCheckoutConfiguration();
      const checkout = await AdyenCheckout(checkoutConfiguration);

      return checkout.create('card').mount(mountPoint);
    },

    async createCVCComponent(mountPoint) {
      if (!mountPoint) {
        return null;
      }

      const commonStoredPaymentMethods = [{
        brand: this.cvcConfig.type === MASTERCARD_TYPE.long ? MASTERCARD_TYPE.short : this.cvcConfig.type,
        expiryMonth: this.cvcConfig.expiry.split('/')[0],
        expiryYear: `20${this.cvcConfig.expiry.split('/')[1]}`,
        holderName: this.cvcConfig.holder,
        id: this.cvcConfig.reference,
        lastFour: this.cvcConfig.number,
        name: this.cvcConfig.type.toUpperCase(),
        supportedShopperInteractions: ['ContAuth'], // Ecommerce, ContAuth
        type: 'scheme',
      }];

      const CVCCheckoutConfiguration = {
        environment,
        locale: this.locale || DEFAULT_LOCALE,
        onChange: onChangeState => {
          const CVCCandidatePaymentMethod = {
            isValid: onChangeState.isValid,
            paymentMethod: {
              ...onChangeState.isValid ? onChangeState.data.paymentMethod : null,
              ...this.cvcConfig,
            },
          };

          this.$emit('update:cvc-candidate-payment-method', CVCCandidatePaymentMethod);
        },

        onError: errorMsg => {
          this.$emit('on:error', errorMsg.errorI18n);
        },
        clientKey: this.originKey,

        paymentMethodsResponse: {
          ...PAYMENT_METHODS_CONFIG,
          storedPaymentMethods: commonStoredPaymentMethods,
        },
      };

      const CVCCardConfiguration = {
        hasHolderName: true,
        holderName: this.cvcConfig.holder,
        type: this.cvcConfig.type === MASTERCARD_TYPE.long ? MASTERCARD_TYPE.short : this.cvcConfig.type,

        expiryMonth: this.cvcConfig.expiry.split('/')[0],
        expiryYear: `20${this.cvcConfig.expiry.split('/')[1]}`,

        storedPaymentMethodId: this.cvcConfig.number,
      };

      const CVCCheckout = await AdyenCheckout(CVCCheckoutConfiguration);
      return CVCCheckout.create('card', CVCCardConfiguration).mount(mountPoint);
    },
  },
};
</script>

